import axios from "axios";
import { handleLogout, refreshTokenService } from "../apis/auth.api";
import {
  refreshTokenTokenFromLocalStorage,
  refreshTokenFromSessionStorage,
  getUserNameFromLocalStorage,
} from "@/Utils/config";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { cognitoConfig } from "@/Utils/CognitoConfig";
import { toast } from "react-toastify";
import { fetchAuthSession } from "aws-amplify/auth";

export function getSessionService() {
  const cognitoUser = cognitoConfig.getCurrentUser();
  return new Promise<CognitoUserSession>((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error("No user found"));
      return;
    }
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
      if (err) {
        handleLogout();
        window.location.reload();
        window.location.href = "/";
        return;
      }
      if (!session) {
        handleLogout();
        window.location.reload();
        window.location.href = "/";
        reject(new Error("No session found"));
        return;
      }
      resolve(session);
    });
  });
}

/***
 * *axios request configs
 */

axios.interceptors.request.use(async (config) => {
  config.baseURL = process.env.NEXT_PUBLIC_API_URL;
  const session = await fetchAuthSession();
  const token = session?.tokens?.idToken?.toString();

  if (token) {
    config.headers.Authorization = `${token}`;
    // console.log(store)
    // if (store && store?.getState()?.userProfile && store?.getState()?.userProfile?.selectedDashboardData?._id) {
    //   config.headers.roleProfileId = `${store?.getState()?.userProfile.selectedDashboardData._id}`;
    // }
  } else {
    if (axios.defaults.headers.common.Authorization) {
      config.headers.common.Authorization = axios.defaults.headers.common;
    }
  }
  return config;
});

/***
 * *axios response configs
 */
axios.interceptors.response.use(
  async (reuslt) => {
    // const response = await ResponseValidator({
    //   isError: reuslt.data.isError,
    //   message: reuslt.data.message,
    // });
    if (reuslt) {
      return reuslt.data;
    }
  },
  async (error) => {
    const expectedErrorRefreshToken = error.response && error.response.status === 401;

    if (expectedErrorRefreshToken) {
      const checkLogin = async () => {
        const refreshToken = refreshTokenTokenFromLocalStorage || refreshTokenFromSessionStorage;
        if (refreshToken) {
          try {
            /* eslint-disable @typescript-eslint/no-explicit-any */
            const tokenData: any = await refreshTokenService(getUserNameFromLocalStorage as string, error);
            console.log(tokenData);
            /* eslint-enable @typescript-eslint/no-explicit-any */
          } catch (err) {
            console.log(err, "error while generating refresh token");
          }
        } else {
          // handleLogout();
          // window.location.reload();
          // window.location.href = "/";
          // return false;
        }
      };
      const response: any = await checkLogin();

      if (response) {
        return Promise.resolve(response);
      }
    }

    const expectedError = error.response && error.response.status >= 400 && error.response.status <= 500;
    if (!expectedError) {
      // userLogout();
      // setTimeout(() => {
      //   window.location.href = "/";
      // }, 1000);
    } else {
      console.log(error.response, "error.response");
      error.response.status !== 401 &&
        (error.response.data?.message
          ? toast.error(error.response.data?.message)
          : toast.error("Something went wrong"));
    }
    return Promise.reject(error);
  }
);

const axiosMethods = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  interceptors: axios.interceptors,
};

export default axiosMethods;
