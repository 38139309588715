import { LoginType, ResetPasswordFormTypes, SignUpFormTypes, SignUpVerificationFormTypes } from "@/Types/Auth/AuthType";

import {
  signUp,
  confirmSignUp,
  signIn,
  signOut,
  resendSignUpCode,
  updateUserAttribute,
  confirmUserAttribute,
  updatePassword,
  resetPassword,
  confirmResetPassword,
  fetchAuthSession,
  getCurrentUser,
  updateUserAttributes,
  UserAttributeKey,
  deleteUser,
} from "aws-amplify/auth";

import Router from "next/router";
import { toast } from "react-toastify";
import { ChangePasswordFormTypes } from "@/Types/FormTypes";
import {
  AuthUserAttributes,
  ConfirmMailPhoneInputType,
  GetCognitoUserDataType,
  UpdateMailPhoneInputType,
} from "@/Types/CognitoTypes";

export const navigateToPage = (path: string) => {
  Router.push(path);
};

export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }
  if (error && typeof error === "object" && "message" in error) {
    return String(error.message);
  }
  if (typeof error === "string") {
    return error;
  }
  return "An error occurred";
}

// Login for aws-cognito with Amplify
export const handleSignIn = async (formData: LoginType) => {
  try {
    const { isSignedIn, nextStep } = await signIn({
      username: formData?.userName,
      password: formData?.password,
    });
    return { isSignedIn, nextStep };
  } catch (error) {
    return error;
  }
};

export const resendSignupVerificationCode = async (username: string) => {
  try {
    return await resendSignUpCode({
      username: String(username),
    });
  } catch (err) {
    return err;
  }
};

// get Current User from AWS Cognito

export const getUserData = async () => {
  const session = await fetchAuthSession();
  if (!session.tokens) {
    return;
  }
  const user: GetCognitoUserDataType | any = {
    ...(await getCurrentUser()),
    ...session?.tokens?.idToken?.payload,
  };
  return user;
};

// Logout From Cognito

export async function handleSignOut() {
  try {
    await signOut();
    window.location.href = "/auth/login";
  } catch (error) {
    console.error(error);
  }
}

// Sign up with cognito

export async function handleSignUp(formData: SignUpFormTypes) {
  try {
    const { isSignUpComplete, userId, nextStep } = await signUp({
      username: `${formData?.userName}`,
      password: formData?.password,
      options: {
        userAttributes: {
          name: `${formData?.firstName + " " + formData?.lastName}`,
          given_name: `${formData?.firstName}`,
          family_name: `${formData?.lastName}`,
          "custom:isOgAdmin": "false",
        },
        autoSignIn: true,
      },
    });
    return { isSignUpComplete, userId, nextStep };
  } catch (error) {
    return error;
  }
}

// Resend verification code

export async function handleSendEmailVerificationCode({ userName }: { userName: string }) {
  let currentState;
  try {
    await resendSignUpCode({
      username: userName,
    });
    currentState = {
      message: "Code sent successfully",
    };
    toast.success("Code sent! Check your email.");
  } catch (error) {
    currentState = {
      errorMessage: getErrorMessage(error),
    };
  }

  return currentState;
}

// Verify User
export async function handleConfirmSignUp(formData: SignUpVerificationFormTypes) {
  try {
    const { isSignUpComplete, nextStep } = await confirmSignUp({
      username: formData?.userName,
      confirmationCode: formData?.code as string,
    });
    // await autoSignIn();
    return { isSignUpComplete, nextStep };
  } catch (error) {
    return error;
  }
}
// Reset Password
export async function handleResetPassword(username: string) {
  try {
    return await resetPassword({ username });
  } catch (error: any) {
    // eslint-disable-line
    return error;
  }
}
//   Confirm Reset password
export async function handleConfirmResetPassword(formData: ResetPasswordFormTypes) {
  try {
    return await confirmResetPassword({
      username: formData?.username as string,
      confirmationCode: formData?.otp,
      newPassword: formData?.confirmPassword,
    });
  } catch (error: any) {
    // eslint-disable-line
    return error;
  }
}

// Change password from settings

export async function handleUpdatePassword(formData: ChangePasswordFormTypes) {
  const currentPassword = formData?.currentPassword;
  const newPassword = formData?.newPassword;

  if (currentPassword === newPassword) {
    return;
  }

  try {
    await updatePassword({
      oldPassword: String(currentPassword),
      newPassword: String(newPassword),
    });
  } catch (error: any) {
    // eslint-disable-line
    return error;
  }

  return "success";
}

export const handleUpdateUserAttribute = async (values: AuthUserAttributes<UserAttributeKey>) => {
  try {
    const updateUser = await updateUserAttributes({
      userAttributes: values,
    });
    return updateUser;
  } catch (err) {
    return err;
  }
};

export const hanldeUpdateUserName = async (value: UpdateMailPhoneInputType) => {
  try {
    const output = await updateUserAttribute({
      userAttribute: {
        attributeKey: value?.attribute,
        value: value?.value,
      },
    });
    return output;
  } catch (error) {
    console.error(error);
    return "error";
  }
};

export async function handleConfirmUserAttribute(values: ConfirmMailPhoneInputType) {
  try {
    await confirmUserAttribute({
      userAttributeKey: values?.attribute,
      confirmationCode: values?.code,
    });
    return true;
  } catch (error: any) {
    // eslint-disable-line
    toast.error(error?.message);
    return error;
  }
}

// Delete User
export const handleDeleteUser = async () => {
  try {
    await deleteUser();
    return true;
  } catch (error: any) {
    // eslint-disable-line
    toast.error(error?.message);
    return error;
  }
};
